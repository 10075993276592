import React from 'react'
import { Account } from '../types/types';
import { useNavigate } from 'react-router-dom';
import { TR, TD, TH } from './common';

export const AccountListItem = (props: {account: Account}) => {
  const navigate = useNavigate()
  const account = props.account
  return (
      <TR onClick={()=>navigate(`/accounts/${account.id}`)}>
        <TD>{account.id}</TD>
        <TD>{account.name.String}</TD>
        <TD>{account.cdn_key}</TD>
      </TR>
      )
}

export const AccountList = (props: {accounts: Account[]}) => {
  return <table style={{borderSpacing: 0}}>
    <thead>
      <tr>
        <TH>Account ID</TH>
        <TH>Name</TH>
        <TH>CDN Key</TH>
      </tr>
    </thead>
    <tbody style={{maxHeight: 'calc(1000px - 300px)'}}>
      {props.accounts.map(account=>(<AccountListItem key={account.id} account={account} />))}
    </tbody>
  </table>
}