import styled, { css } from "styled-components";
import { Status } from "../types/types";
import { useEffect, useState } from "react";
import JSONPretty from "react-json-pretty";
import Switch from "react-switch";

export const Button = styled.button<{ $primary?: boolean; $danger?: boolean }>`
  --accent-color: white;

  /* This renders the buttons above... Edit me! */
  border-radius: 3px;
  border: 1px solid var(--accent-color);
  color: var(--accent-color);
  display: inline-block;
  margin: 5px 10px;
  padding: 5px 10px;
  transition: all 200ms ease-in-out;
  max-width: 150px;

  &:hover {
    filter: brightness(0.85);
  }

  &:active {
    filter: brightness(1);
  }
  cursor: pointer;

  /* The GitHub button is a primary button
   * edit this to target it specifically! */
  ${props => props.$primary && css`
    background: #55f;
    color: white;
  `}
  ${props => props.$danger && css`
    background: #f55;
    color: white;
  `}
`

export const Select = styled.select`
  border: 1px solid #ddf;
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0 10px;
`

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw - 200px);
  height: 100vh;
  overflow-y: scroll;
  position: relative;
`
export const InnerPage = styled.div`
  height: 99vh;
  overflow: scroll;
`

export const PageHeader = styled.h2`
  margin-left: 30px;
  &:before {
    content: '@ ';
  }
`

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  padding: 20px;
`

export const DataRow = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px dashed #ccf;
  &:hover {
    background-color: #eef;
  }
  align-items: center;
`

export const DataRowCell = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  &:last-child {
    flex-grow: 3;
  }
`

export const DataLabel = styled.p`
  font-weight: bold;
`

export const DataValue = styled.p`
  font-weight: normal;
`

export const colorFromStatus = (status: Status): string => {
  switch(status){
    case "ERROR": return "#f00"
    case "IN_PROGRES": return "#00f"
    case "SUCCESS": return "#0f0"
    case "NOT_STARTED": return "#ccf"
    default: return "#ccf"
  }
}

export const ExpandableSection = (props: React.PropsWithChildren<{defaultExpanded?: boolean, hideIcon?: boolean, header?: React.ReactNode, expanded?:boolean}>) => {
  const [isExpanded, setIsExpanded] = useState(!!props.defaultExpanded)
  useEffect(()=>{
    if(props.expanded !== undefined)
      setIsExpanded(!!props.expanded)
    }, [props.expanded])
  return <>
      {props.header ? <div style={{position: 'relative', cursor: 'pointer'}} onClick={()=>setIsExpanded(!isExpanded)}>
        {props.header}
        {!props.hideIcon ? <span style={{rotate: '90deg', fontSize: '20px', position: 'absolute', right:'20px', top: '7px'}}>{isExpanded? '<' : '>'}</span> : null}
      </div> : null}
      {isExpanded? props.children : null}
    </>
}


export const TR = styled.tr`
  &:nth-child(odd) {
    background-color: #eef;
  }
  &:hover {
    background-color: #aaf;
    color: white;
    cursor: pointer;
  }
`

export const TD = styled.td`
  padding: 10px 3px 10px 10px;
  margin: 0;
  border: 0;
  position: sticky;
`

export const TH = styled.th`
  text-align: left;
  padding: 10px 3px 10px 10px;
`

export const TextInput = styled.input.attrs(props=>({
  type: "text"
}))`
  border: 1px solid #ddf;
  height: 30px;
  padding-left: 10px;
  margin: 0 10px;
`
export const NumberInput = styled.input.attrs(props=>({
  type: "number"
}))`
  border: 1px solid #ddf;
  height: 30px;
  padding-left: 10px;
  margin: 0 10px;
`

export function inputForValueType<Type>(value: Type, onChange: (newValue: Type) => void, placeholder?: string): React.ReactNode {
  switch (typeof value) {
    case "boolean":
      return  <div style={{marginLeft: '10px'}}><Switch 
                onColor="#86d3ff"
                handleDiameter={15}
                uncheckedIcon={false}
                checkedIcon={false}
                onHandleColor="#2693e6"
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={15}
                width={30}
                checked={value}
                onChange={()=>onChange(!value as Type)} 
              /></div>
    case "string":
      return <TextInput placeholder={placeholder} defaultValue={value} onBlur={(evt)=>onChange(evt.target.value as Type)} />
    case "number":
      return <NumberInput placeholder={placeholder} defaultValue={value} onBlur={(evt)=>onChange(Number.parseInt(evt.target.value) as Type)} />
    default:
      return <TextInput defaultValue={value as string} onBlur={(evt)=>onChange(evt.target.value as Type)} />
  }
}

export function isJSON(str: any) {
  if(str === true || str === false){
    return false
  }
  if(!isNaN(parseFloat(str))){
    return false
  }
  try {
      return JSON.parse(str) && !!str;
  } catch (e) {
      return false;
  }
}
export function dataRow(label: string, value: string | React.ReactNode, onChange?: (value: any) => void, placeholder?: string): React.ReactNode {
  return (isJSON(value) ? 
    <ExpandableSection key={label+value} header={<DataRow>
        <DataRowCell>
          <DataLabel>{label}</DataLabel>
        </DataRowCell>
        <DataRowCell>
          <DataValue><i style={{color: '#00f'}}>Show JSON</i></DataValue>
        </DataRowCell>
      </DataRow>}>
      <DataRow>
        <JSONPretty style={{padding: '10px'}} data={value} />
      </DataRow>
    </ExpandableSection> 
    : 
    <DataRow key={label+value}>
      <DataRowCell>
        <DataLabel>{label}</DataLabel>
      </DataRowCell>
      <DataRowCell>
        {onChange ? inputForValueType(value, onChange, placeholder) : <DataValue>{value}</DataValue>}
      </DataRowCell>
    </DataRow>)
}


