import React from 'react'
import { JobWithAccountName } from '../types/types';
import { Link, useNavigate } from 'react-router-dom';
import { TR, TD, TH } from './common';
import { extraDataToString, timeStrToTimeStr } from '../util/utils';

export const JobListItem = (props: {job: JobWithAccountName}) => {
  const navigate = useNavigate()
  const job = props.job
  
  return (
      <TR onClick={()=>navigate(`/accounts/${job.account_id}/jobs/${job.id}`)}>
        <TD>{job.created_at && timeStrToTimeStr(job.created_at.Time)}</TD>
        <TD><Link onClick={(evt)=>evt.stopPropagation()} to={`/?accountId=${job.account_id}`}>{job.account_name ? job.account_name : job.account_id}</Link></TD>
        <TD>{job.id}</TD>
        <TD>{extraDataToString(job.extra_data)}</TD>
        <TD>{job.media_id}</TD>
        <TD>{job.status}</TD>
        <TD>{job.status_message}</TD>
      </TR>
      )
}

export const JobList = (props: {jobs: JobWithAccountName[]}) => {
  return <table style={{borderSpacing: 0}}>
    <thead>
      <tr>
        <TH>Created</TH>
        <TH>Account</TH>
        <TH>Job ID</TH>
        <TH>Info</TH>
        <TH>Media ID</TH>
        <TH>Status</TH>
        <TH>Message</TH>
      </tr>
    </thead>
    <tbody style={{maxHeight: 'calc(1000px - 300px)'}}>
      {props.jobs.map(job=>(<JobListItem key={job.id} job={job} />))}
    </tbody>
  </table>
}