import React, { useContext } from 'react'
import { AuthContext, InitialAuthContext } from '../context/auth'

import Home from '../pages/home'
import { Link, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import Login from '../pages/login';
import Profile from '../pages/profile';
import Profiles from '../pages/profiles';
import Account from '../pages/account';
import Accounts from '../pages/accounts';
import pegacorn from '../pages/pegacorn.png'
import Job from '../pages/job';
import Switch from "react-switch";
import { UpdateContext } from "../context/update"
import { Select } from '../components/common';


type Props = {}

const PrivateRoutes = () => {
  const { authenticated } = useContext(AuthContext)

  if(!authenticated.token) return <Navigate to='/login' replace />

  return <Outlet />
}

const LoggedInRoutes = (props: Props) => {
  const { authenticated, setAuthenticated } = useContext(AuthContext)
  const { update, setUpdate } = useContext(UpdateContext)

  const location = useLocation()


  return (
    <>
      {authenticated.token ? (<nav>
        <img style={{margin: '0 auto'}} src={pegacorn} width="100px" alt="pegacorn"/>
        <h2 style={{textAlign: 'center', color: '#338'}}>Qbrick<br/>Pegacoder</h2>
        {authenticated.availableAccounts.length > 1 ? 
          <Select value={authenticated.account} onChange={(evt) => {
            setAuthenticated({...authenticated, account: evt.target.value})
          }}>
            {authenticated.availableAccounts.map(acc=><option selected={acc === authenticated.account} key={acc} value={acc}>{acc}</option>)}
          </Select> : 
          <p style={{textAlign: 'center'}}>{authenticated.account}</p> 
        }
        <ul>
          <Link to="/" className={location.pathname === '/' ? "active" : undefined}>
            Home
          </Link>
          {authenticated.account === "" ? <>
            <Link to="/accounts" className={location.pathname === '/accounts' ? "active" : undefined}>
              Accounts
            </Link>
            <Link to="/profiles" className={location.pathname === '/profiles' ? "active" : undefined}>
              Profiles
            </Link>
          </> : null}
          <Link to="/login" className={location.pathname === '/login' ? "active" : undefined} onClick={()=>setAuthenticated(InitialAuthContext.authenticated)}>
            Logout
          </Link>
        </ul>
        <div style={{display: 'flex', marginTop: 'auto', marginBottom: '10px', justifyContent: 'center', alignContent: 'center'}}>
          <p style={{margin: 0, marginRight: '10px'}}>Auto-update</p>
          <Switch 
            onColor="#86d3ff"
            handleDiameter={15}
            uncheckedIcon={false}
            checkedIcon={false}
            onHandleColor="#2693e6"
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={15}
            width={30}
            checked={update}
            onChange={()=>setUpdate(!update)} 
          />
        </div>
      </nav>) : null}

      <Routes>
        <Route path='/login' element={<Login />}/>
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Home />} />
          <Route path="/accounts/:accountId/jobs/:jobId" element={<Job />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profiles/:profileId" element={<Profile />} />
          <Route path="/profiles" element={<Profiles />} />
          <Route path="/accounts/:accountId" element={<Account />} />
          <Route path="/accounts" element={<Accounts />} />
        </Route>
      </Routes>
    </>
  )
}

export default LoggedInRoutes