import {Job} from '../types/types'

export const timeStrToTimeStr = (timsStr: string): string => {
  const tstr = Date.parse(timsStr)
  const d = new Date(tstr)
  return d.toLocaleDateString() + " " + d.toLocaleTimeString()
}

export const secondsToStringFmt = (seconds?: number): string => {
  if(!seconds)
    return "?s"
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds - (hours*3600))/60)
  const secs = Math.floor(seconds - (hours*3600) - (minutes*60))
  return "" +
    hours.toString().padStart(2, '0') + ':' +
    minutes.toString().padStart(2, '0') + ':' +
    secs.toString().padStart(2, '0')
}

export const extraDataToString = (extra?: {Data?:{[key: string]: any}}): string => {
  if(!extra || !extra.Data) return ""

  const lengthStr = secondsToStringFmt(extra.Data['videoLength'])
  return `${lengthStr}, ${extra.Data['width']}x${extra.Data['height']}`
}


export type TransposedJobBucket = {
  SUCCESS: number,
  ERROR: number,
  IN_PROGRESS: number,
  startTime: string,
  endTime: string,
}
export const transposeJobs = (jobs: Job[], nBuckets: number): TransposedJobBucket[] => {
  if(jobs.length ===0) return []
  const endTime = Date.now()
  const startTime = Date.parse(jobs[jobs.length-1].created_at.Time)
  const duration = endTime - startTime
  const bucketTimespan = duration / nBuckets

  let buckets: TransposedJobBucket[] = []

  for(let i=0;i<nBuckets;i++){
    const bucketStart = startTime + (i*bucketTimespan)
    const bucketEnd = bucketStart + bucketTimespan
    const currentBucket = {
      SUCCESS: 0,
      ERROR: 0,
      IN_PROGRESS: 0,
      endTime: new Date(bucketEnd).toLocaleDateString() + ' ' + new Date(bucketEnd).toLocaleTimeString(),
      startTime: new Date(bucketStart).toLocaleDateString() + ' ' + new Date(bucketStart).toLocaleTimeString(),
    }
    buckets = [...buckets, currentBucket]
    const jobsInBucket = jobs.filter(job=>{
      const jStart = Date.parse(job.created_at.Time)
      const jEnd = job.completed_at.Valid ? Date.parse(job.completed_at.Time) : undefined
      
      if(jStart < bucketEnd && (!jEnd || jEnd >= bucketStart)) {
        return true
      }
      return false
    })
    currentBucket.SUCCESS = jobsInBucket.filter(j=>j.status === 'SUCCESS').length
    currentBucket.ERROR = jobsInBucket.filter(j=>j.status === 'ERROR').length
    currentBucket.IN_PROGRESS = jobsInBucket.filter(j=>j.status !== 'SUCCESS' && j.status !== 'ERROR').length
  }
  return buckets
}

export const replaceOrAddToNullDataArray = <Type extends {id: string}>(obj: {Valid: boolean; Data?: Type[] | null}, newOrUpdatedObject: Type) => {
  const value = {...obj}
  if(obj.Valid && obj.Data && obj.Data.find(d=>d!.id === newOrUpdatedObject.id)) {
    value.Data = obj.Data.map(d=>{
      if(d!.id === newOrUpdatedObject.id)
        return {...newOrUpdatedObject}
      return {...d}
    })
  } else {
    value.Data = [{...newOrUpdatedObject}]
  }
  value.Valid = true
  return value
}