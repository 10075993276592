import React from 'react';
import './App.css';


import { AuthProvider } from "./context/auth"
import LoggedInRoutes from './routes/routes';
import { UpdateProvider } from './context/update';



function App() {
  return (
    <div className="container">
      <AuthProvider>
        <UpdateProvider>
          <LoggedInRoutes />
        </UpdateProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
