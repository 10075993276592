import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Config } from '../config/config';
import { AuthContext, InitialAuthContext } from '../context/auth';
import {Job, JobFiles, JobStep} from '../types/types'
import { Button, DataContainer, DataLabel, DataRow, DataRowCell, ExpandableSection, Page, PageHeader, colorFromStatus, dataRow } from '../components/common';
import 'react-json-pretty/themes/monikai.css';
import { UpdateContext } from '../context/update';
import { Line } from 'rc-progress';
import { extraDataToString } from '../util/utils';


function JobPage() {
  const {jobId, accountId} = useParams()
  const {authenticated, setAuthenticated} = useContext(AuthContext)
  const {update} = useContext(UpdateContext)
  const [doUpdate, setDoUpdate] = useState(1)

  const [job, setJob] = useState<Job | undefined>(undefined)
  const [jobFiles, setJobFiles] = useState<JobFiles>([])

  const [donwloadProgress, setDownloadProgress] = useState<{[key: string]: number}>({})

  const loadJob = useCallback(async () => {
    let res = await fetch(
      `${Config.base_url}/api/v1/accounts/${accountId}/jobs/${jobId}?refresh=true`,
      {
        headers: {
          Authorization: "Bearer " + authenticated.token,
        },
      }
    );
    if (res.status !== 200) {
      if (res.status === 401) {
        setAuthenticated(InitialAuthContext.authenticated);
      }
      return;
    }
    let resJson = await res.json();
    if (resJson.ok){
      setJob(resJson.job as Job)
    } else {
      return
    }

    let filesRes = await fetch(
      `${Config.base_url}/api/v1/accounts/${resJson.job.account_id}/jobs/${resJson.job.id}/files`,
      {
        headers: {
          Authorization: "Bearer " + authenticated.token,
        },
      }
    );
    if (res.status === 200) {
      const filesJson = await filesRes.json()
      if (filesJson.ok) setJobFiles(filesJson.files as JobFiles)
    }
  }, [accountId, authenticated.token, jobId, setAuthenticated])

  useEffect(()=>{
    setTimeout(()=>setDoUpdate(doUpdate+1), 5000)
  }, [doUpdate])
  useEffect(()=>{
      if(update) {
        loadJob()
      }
    }, [update, doUpdate, loadJob])

  useEffect(()=>{loadJob()}, [loadJob])



  const downloadFile = useCallback(async (file: string) => {
    const options = {
      headers: {
        Authorization: "Bearer " + authenticated.token,
      },
    };
    const res = await fetch(
      `${Config.base_url}/api/v1/accounts/${job?.account_id}/jobs/${job?.id}/files/${file}`,
      options
    )
    if(!res.ok || res.body === null || res.headers === null)
      return null
    
    const newProgress = {...donwloadProgress}
    newProgress[file] = 0
    setDownloadProgress(newProgress)

    const reader = res.body.getReader()
    const contentLength = +res.headers.get('Content-Length')!

    let receivedLength = 0
    let chunks = []

    while(true) {
      const {done, value} = await reader.read()
    
      if (done) {
        break
      }
    
      chunks.push(value)
      receivedLength += value.length

      const upProgress = {...donwloadProgress}
      upProgress[file] = receivedLength / contentLength
      setDownloadProgress(upProgress)
    
      console.log(`Received ${receivedLength} of ${contentLength}`)
    }
    const delProgress = {...donwloadProgress}
    delete delProgress[file]
    setDownloadProgress(delProgress)

    // const blob = await res.blob()
    
    var fileBlob = window.URL.createObjectURL(new Blob(chunks));
    
    var fileLink = document.createElement("a");
    fileLink.href = fileBlob;

    fileLink.download = file;

    fileLink.click();
  },[authenticated, job, setDownloadProgress, donwloadProgress])

  return (
    <Page>
      <PageHeader>Job {job? job.id : null} {job ? <span style={{color: colorFromStatus(job.status), position: 'absolute', right:'20px'}}>{job.status}</span> : null}</PageHeader>
      {job ? <DataContainer>      
        {dataRow("Created", job.created_at.Valid ? job.created_at.Time : "Not set")}
        {dataRow("Completed", job.completed_at.Valid ? job.completed_at.Time : "Not set")}
        {dataRow("Info", extraDataToString(job.extra_data))}
        {job.flow_id && dataRow("Flow", job.flow_id)}
        {job.flow_type && dataRow("Flow", job.flow_type)}
        {dataRow("Media ID", job.media_id)}
        {dataRow("Account ID", job.account_id)}
        {dataRow("Message", job.status_message)}
        {job.steps.map((jobStep: JobStep)=>{
          return <ExpandableSection key={jobStep.id} header={
            dataRow(`Step (${jobStep.type})`, <><span style={{color: colorFromStatus(jobStep.status), fontWeight: 'bold'}}>{jobStep.status}</span>, {jobStep.id}</>)
          }>
            <div style={{display: 'flex'}}><div style={{width: '5px', height: '100%', backgroundColor:'#ddf'}}></div><DataContainer>
              {jobStep.result && Array.from(Object.keys(jobStep.result)).map((key: string)=>{
                return dataRow(key, jobStep.result![key])
              })}
              {dataRow("Run after", jobStep.run_after?.join(', '))}
              {dataRow("Priority", jobStep.priority.toString())}
              {jobStep.params && Array.from(Object.keys(jobStep.params)).map((key: string)=>{
                return dataRow("Param: " + key, jobStep.params![key])
              })}
            </DataContainer></div>
          </ExpandableSection>
        })}
      </DataContainer>: null}
      {jobFiles.length > 0 ? <DataContainer><ExpandableSection header={<DataRow>
        <DataRowCell><DataLabel>{jobFiles.length} Files</DataLabel></DataRowCell>
      </DataRow>}>
          {jobFiles.map(f=><DataRow>
              <DataRowCell>
                <DataLabel>{f}</DataLabel>
              </DataRowCell>
              <DataRowCell>
                {!(f in donwloadProgress) ? 
                  <Button style={{marginLeft: '40px', marginRight:'auto'}} $primary={true} onClick={()=>downloadFile(f)}>Download</Button>
                  :
                  <DataRowCell><div style={{width: 'calc(100% - 60px)', marginLeft: '40px'}}>Downloading...</div><div style={{width: 'calc(100% - 60px)', marginLeft: '40px'}}><Line percent={donwloadProgress[f]*100} strokeWidth={1} strokeColor="#55f" /></div></DataRowCell>
                }
              </DataRowCell>
              </DataRow>)}
            </ExpandableSection></DataContainer> : null}
    </Page>
  )
}

export default JobPage