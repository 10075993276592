import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Config } from '../config/config';
import { AuthContext, InitialAuthContext } from '../context/auth';
import {AWSCredentialDetails, Account, AccountProfile, AccountStats, Credential, Profile, StepType } from '../types/types'
import { Button, DataContainer, DataLabel, DataRow, DataRowCell, DataValue, ExpandableSection, Page, PageHeader, Select, TextInput, dataRow } from '../components/common';
import 'react-json-pretty/themes/monikai.css';
import { ConfirmModal } from '../components/modal';
import { replaceOrAddToNullDataArray } from '../util/utils';

const NEW_ACCOUNT_TEMPATE: Account = {
  id: '',
  name: {String: "", Valid: false},
  cdn_key: "",
  ftp_pwd: "",
  ftp_user: "",
  qvp_user: "",
  qvp_pwd: "",
  smil_distribution_link: "https://httpcache0-{cdnkey}-cacheod0.dna.ip-only.net/{cdnkey}-cacheod0/_definst_/smil:{path}",
  image_distribution_link: "https://httpcache0-{cdnkey}-cachedown0.dna.ip-only.net/{cdnkey}-cachedown0{path}",
  video_distribution_link: "https://httpcache0-{cdnkey}-cachedown0.dna.ip-only.net/{cdnkey}-cachedown0{path}",
  credentials: {
    Valid: false,
    Data: null,
  }
}

function AccountPage() {
  const {accountId} = useParams()
  const {authenticated, setAuthenticated} = useContext(AuthContext)
  const navigate = useNavigate()

  const [account, setAccount] = useState<Account | undefined>(accountId === 'new' ? NEW_ACCOUNT_TEMPATE : undefined)
  const [accountProfiles, setAccountProfiles] = useState<AccountProfile[]>([])
  const [profiles, setProfiles] = useState<Profile[]>([])
  const [stats, setStats] = useState<AccountStats>([])
  const [statPeriod, setStatPeriod] = useState<"THIS_MONTH" | "LAST_MONTH" | "2MONTHS_AGO" | "3MONTHS_AGO">("THIS_MONTH")

  const [editingFlow, setEditingFlow] = useState<AccountProfile | undefined>()
  const [addEditCredential, setAddEditCredential] = useState<Credential | undefined>()
  

  const [newAccountProfile, setNewAccountProfile] = useState<AccountProfile>({
    account_id: "",
    account_profile_id: "",
    profile_id: "",
    extra_steps: {
      Valid: false,
      Data: null,
    }
  })

  const loadAccount = useCallback(async () => {
    if(accountId === 'new')
      return
    let res = await fetch(
      `${Config.base_url}/api/v1/admin/accounts/${accountId}`,
      {
        headers: {
          Authorization: "Bearer " + authenticated.token,
        },
      }
    );
    if (res.status !== 200) {
      if (res.status === 401) {
        setAuthenticated(InitialAuthContext.authenticated);
      }
      return;
    }
    let resJson = await res.json();
    if (resJson.ok) setAccount(resJson.account as Account);
  }, [accountId, authenticated.token, setAuthenticated])

  const loadAccountProfiles = useCallback(async () => {
    if(accountId === 'new')
      return
    let res = await fetch(
      `${Config.base_url}/api/v1/admin/accounts/${accountId}/profiles`,
      {
        headers: {
          Authorization: "Bearer " + authenticated.token,
        },
      }
    );
    if (res.status !== 200) {
      if (res.status === 401) {
        setAuthenticated(InitialAuthContext.authenticated);
      }
      return;
    }
    let resJson = await res.json();
    if (resJson.ok) setAccountProfiles(resJson.account_profiles as AccountProfile[]);
  }, [accountId, authenticated.token, setAuthenticated])

  const loadStats = useCallback(async (from: string, to: string) => {
    if(accountId === 'new')
      return
    let res = await fetch(
      `${Config.base_url}/api/v1/accounts/${accountId}/stats?from=${from}&to=${to}`,
      {
        headers: {
          Authorization: "Bearer " + authenticated.token,
        },
      }
    );
    if (res.status !== 200) {
      if (res.status === 401) {
        setAuthenticated(InitialAuthContext.authenticated);
      }
      return;
    }
    let resJson = await res.json();
    if (resJson.ok) setStats(resJson.stats as AccountStats);
  }, [accountId, authenticated.token, setAuthenticated, setStats])

  const loadProfiles = useCallback(async () => {
    if(accountId === 'new')
      return
    let res = await fetch(
      `${Config.base_url}/api/v1/admin/profiles`,
      {
        headers: {
          Authorization: "Bearer " + authenticated.token,
        },
      }
    );
    if (res.status !== 200) {
      if (res.status === 401) {
        setAuthenticated(InitialAuthContext.authenticated);
      }
      return;
    }
    let resJson = await res.json();
    if (resJson.ok) {
      const prs = resJson.profiles as Profile[]
      setProfiles(prs)
    }
  }, [authenticated, setAuthenticated, accountId])

  useEffect(()=>{
    if(profiles.length > 0){
      setNewAccountProfile({...newAccountProfile, profile_id: profiles[0].id})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profiles])

  useEffect(()=>{
    loadAccount()
    loadAccountProfiles()
    loadProfiles()
  }, [loadAccount, loadAccountProfiles, loadProfiles])

  return (
    <Page>
      <PageHeader>Account {accountId}</PageHeader>
      {account ? 
        <DataContainer>      
          {accountId === 'new' ? dataRow("Account ID", account.id, (value: string)=>setAccount({...account, id: value})) : null}
          {dataRow("Name", account.name.String, (value: string)=>setAccount({...account, name: {String: value, Valid: true}}))}
          {dataRow("CDN Key", account.cdn_key, (value: string)=>setAccount({...account, cdn_key: value}))}
          {dataRow("FTP User", account.ftp_user, (value: string)=>setAccount({...account, ftp_user: value}))}
          {dataRow("FTP Password", account.ftp_pwd, (value: string)=>setAccount({...account, ftp_pwd: value}))}
          {dataRow("QVP User", account.qvp_user, (value: string)=>setAccount({...account, qvp_user: value}))}
          {dataRow("QVP Password", account.qvp_pwd, (value: string)=>setAccount({...account, qvp_pwd: value}))}
          {dataRow("Video link", account.video_distribution_link, (value: string)=>setAccount({...account, video_distribution_link: value}))}
          {dataRow("Image link", account.image_distribution_link, (value: string)=>setAccount({...account, image_distribution_link: value}))}
          {dataRow("SMIL link", account.smil_distribution_link, (value: string)=>setAccount({...account, smil_distribution_link: value}))}
          {accountId !== 'new' ? <ExpandableSection defaultExpanded={true} header={<DataRow><DataLabel>Account Profiles</DataLabel></DataRow>}>
            <DataContainer>
              <i>Changes to Account Profiles are done instantly!</i>
              {accountProfiles.length > 0 ? 
                accountProfiles.map(accountProfile => (
                  <DataRow key={accountProfile.account_profile_id}>
                    <DataRowCell><DataLabel>{accountProfile.account_profile_id}</DataLabel></DataRowCell>
                    <DataRowCell><DataValue>{'-->'}</DataValue></DataRowCell>
                    <DataRowCell><DataValue><a href={'/profiles/'+accountProfile.profile_id}>{accountProfile.profile_id}</a></DataValue></DataRowCell>
                    <DataRowCell><DataValue>{accountProfile.extra_steps.Valid ? accountProfile.extra_steps.Data?.length + " extra steps" : "No extra steps"}</DataValue></DataRowCell>
                    <DataRowCell><Button $primary={true} onClick={()=>setEditingFlow(accountProfile)}>Edit flow</Button></DataRowCell>
                    <DataRowCell style={{flexGrow: 0}}><Button $danger={true} onClick={async ()=>{
                      let res = await fetch(
                        `${Config.base_url}/api/v1/admin/accounts/${accountProfile.account_id}/profiles/${accountProfile.account_profile_id}`,
                        {
                          method: 'DELETE',
                          headers: {
                            Authorization: "Bearer " + authenticated.token,
                            'Content-Type': 'application/json',
                          },
                        }
                      )
                      if(res.ok) {
                        loadAccountProfiles()
                      }
                    }}>Remove</Button></DataRowCell>
                  </DataRow>                  
                ))
                : 
                <DataRow><DataRowCell><DataValue>No Profiles Available...</DataValue></DataRowCell></DataRow>
              }
              <DataRow>
                <DataRowCell><DataLabel>New</DataLabel></DataRowCell>
                <DataRowCell><TextInput placeholder='Profile name' onBlur={(evt) => setNewAccountProfile({...newAccountProfile, account_profile_id: evt.target.value})}/></DataRowCell>
                <DataRowCell><Select value={newAccountProfile.profile_id} onChange={(evt)=>setNewAccountProfile({...newAccountProfile, profile_id: evt.target.value})}>
                    {profiles.map(profile=>(<option key={profile.id} value={profile.id}>{profile.id}</option>))}
                  </Select></DataRowCell>
                <DataRowCell><Button onClick={async ()=>{
                  if(!newAccountProfile.profile_id || !newAccountProfile.account_profile_id)
                    return
                  let res = await fetch(
                    `${Config.base_url}/api/v1/admin/profiles/${newAccountProfile.profile_id}/accounts`,
                    {
                      method: 'POST',
                      headers: {
                        Authorization: "Bearer " + authenticated.token,
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        account_id: accountId,
                        account_profile_id: newAccountProfile.account_profile_id,
                      })
                    }
                  )
                  if(res.ok) {
                    loadAccountProfiles()
                  }
                }} $primary={true}>Add Profile</Button></DataRowCell>
              </DataRow>
            </DataContainer>
          </ExpandableSection> : null}
          {accountId !== 'new' ? <ExpandableSection defaultExpanded={true} header={<DataRow><DataLabel>Credentials</DataLabel></DataRow>}>
            <DataContainer>
              {account.credentials.Valid && account.credentials.Data && account.credentials.Data.length > 0 ? 
                account.credentials.Data.map(credential => (
                  <DataRow key={credential.id}>
                    <DataRowCell><DataLabel>{credential.type}</DataLabel></DataRowCell>
                    <DataRowCell><DataValue>{credential.id}</DataValue></DataRowCell>
                    <DataRowCell><Button $primary={true} onClick={()=>{setAddEditCredential(credential)}}>Edit credential</Button></DataRowCell>
                    <DataRowCell style={{flexGrow: 0}}><Button $danger={true} onClick={async ()=>{}}>Remove</Button></DataRowCell>
                  </DataRow>                  
                ))
                : 
                <DataRow><DataRowCell><DataValue>No Credentials Available...</DataValue></DataRowCell></DataRow>
              }
              <DataRow>
                <DataRowCell><DataLabel>New</DataLabel></DataRowCell>
                <DataRowCell><Button onClick={async ()=>{
                  setAddEditCredential({
                    id: "new",
                    type: "AWS",
                    details: {
                      bucket: "",
                      region: "",
                      access_key_id: "",
                      secret_access_key: "",
                      session_token: "",
                    },
                  })
                }} $primary={true}>Add Credential</Button></DataRowCell>
              </DataRow>
            </DataContainer>
          </ExpandableSection> : null}
          {accountId !== 'new' ? <ExpandableSection defaultExpanded={true} header={<DataRow><DataLabel>Statistics</DataLabel></DataRow>}>
            <div style={{margin: '5px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
              <Select value={statPeriod} onChange={(evt) => {
                    setStatPeriod(evt.target.value as any as "THIS_MONTH" | "LAST_MONTH")
                  }}>
                  <option selected={statPeriod === "THIS_MONTH"} value={"THIS_MONTH"}>This month</option>
                  <option selected={statPeriod === "LAST_MONTH"} value={"LAST_MONTH"}>Last month</option>
                  <option selected={statPeriod === "2MONTHS_AGO"} value={"2MONTHS_AGO"}>2 months ago</option>
                  <option selected={statPeriod === "3MONTHS_AGO"} value={"3MONTHS_AGO"}>3 months ago</option>
              </Select>
              <Button onClick={async ()=>{
                let currDate = new Date()
                switch(statPeriod) {
                  case "LAST_MONTH":
                    currDate.setMonth(currDate.getMonth() - 1)
                    break
                  case "2MONTHS_AGO":
                    currDate.setMonth(currDate.getMonth() - 2)
                    break
                  case "3MONTHS_AGO":
                    currDate.setMonth(currDate.getMonth() - 3)
                    break
                }
                  
                let fromyear = currDate.getUTCFullYear()
                let fromm = currDate.getUTCMonth()
                let toyear = fromyear
                let tom = fromm + 1
                if(tom > 11){
                  toyear += 1
                  tom = tom - 12
                }
                const frommonth = (fromm + 1).toString().padStart(2,"0")
                const tomonth = (tom + 1).toString().padStart(2,"0")
                loadStats(`${fromyear}-${frommonth}-01`, `${toyear}-${tomonth}-01`)  
              }} $primary={true}>Load</Button>
            </div>
            <DataContainer>
              {stats.map(stat=>(<DataRow>
                <DataRowCell><DataLabel>{stat.Status}</DataLabel></DataRowCell>
                <DataRowCell><DataLabel>{stat.ProfileID}</DataLabel></DataRowCell>
                <DataRowCell><DataValue>{stat.Minutes}</DataValue></DataRowCell>
                <DataRowCell><DataValue>{stat.NumberOfJobs}</DataValue></DataRowCell>
              </DataRow>))}
            </DataContainer>
          </ExpandableSection> : null}
          <Button style={{marginTop: '20px', marginLeft: 'auto'}} onClick={async ()=>{
            if(!account.id || account.id === 'new'){
              return
            }
            let res = await fetch(
              `${Config.base_url}/api/v1/admin/accounts${accountId === 'new' ? '' : `/${accountId}`}`,
              {
                method: accountId === 'new' ? 'POST' : 'PUT',
                headers: {
                  Authorization: "Bearer " + authenticated.token,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(account)
              }
            )
            if(res.ok) {
              if(accountId === 'new') {
                let res = await fetch(
                  `${Config.base_url}/api/v1/admin/profiles/qbrick-standard/accounts`,
                  {
                    method: 'POST',
                    headers: {
                      Authorization: "Bearer " + authenticated.token,
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      account_id: account.id,
                      account_profile_id: 'qbrick-standard',
                    })
                  }
                )
                if(res.ok)
                  navigate(`/accounts/${account.id}`)
              } else {
                loadAccount()
              }
            }           
          }} $primary={true}>{accountId === 'new' ? 'Create' : 'Save'}</Button>
        </DataContainer>
      : null}
      {account && editingFlow &&<ConfirmModal
        width="600px"
        isOpen={!!editingFlow}
        header={`You're editing the flow '${editingFlow.account_profile_id}'`}
        text={<>
          <DataContainer>
            <DataRow>
              <DataRowCell><DataLabel>Encoding Profile</DataLabel></DataRowCell>
              <DataRowCell>
                  <DataValue>
                    <Select value={editingFlow.profile_id} onChange={(evt)=>setEditingFlow({...editingFlow, profile_id: evt.target.value})}>
                      {profiles.map(profile=>(<option key={profile.id} value={profile.id}>{profile.id}</option>))}
                    </Select>
                </DataValue>
              </DataRowCell>
            </DataRow>
                <DataRow><DataRowCell><DataLabel>Extra Job Steps</DataLabel></DataRowCell></DataRow>
                {(editingFlow.extra_steps.Valid && editingFlow.extra_steps.Data?.map(step=> (
                  <DataRow>
                    <DataRowCell>                  
                      <DataLabel>{step.type}</DataLabel>
                    </DataRowCell>
                    <DataRowCell>      
                      <DataValue>{step.id}</DataValue>   
                    </DataRowCell> 
                    <DataRowCell>      
                      <DataValue>{step.priority}</DataValue>
                    </DataRowCell>
                    <DataRowCell>      
                      <DataValue>{step.type === "archive" ? 
                        <Select value={step.details["credentials-id"]} onChange={(evt)=>{
                            setEditingFlow({...editingFlow, extra_steps: replaceOrAddToNullDataArray(editingFlow.extra_steps, {...step, details: {"credentials-id": evt.target.value}})})
                          }}>
                          {account.credentials.Valid && account.credentials.Data && account.credentials.Data.map(c=>(<option key={c.id} value={c.id}>{c.type} - {c.id}</option>))}
                        </Select> : null}
                      </DataValue>
                    </DataRowCell>
                  </DataRow>
                ))) || <DataRow><DataValue>No extra steps</DataValue></DataRow>}
                <div style={{display: "flex", marginTop: "10px"}}>
                  <DataRowCell>
                  <Select value={"archive"} onChange={(evt)=>{}}>
                    {["archive"].map(t=>(<option key={t} value={t}>{t}</option>))}
                  </Select>
                  </DataRowCell>
                  {account.credentials.Valid && account.credentials.Data?.length ? <DataRowCell><Button style={{marginLeft: 'auto'}} $primary={true} onClick={()=>{
                    setEditingFlow({...editingFlow, extra_steps: {
                      Valid: true,
                      Data: editingFlow.extra_steps.Data ? [...editingFlow.extra_steps.Data, {
                        id: "",
                        type: "archive",
                        priority: 5,
                        details: {
                          "credentials-id": account.credentials.Data![0].id
                        }
                      }] : [{
                        id: "",
                        type: "archive",
                        priority: 5,
                        details: {
                          "credentials-id": account.credentials.Data![0].id
                        }
                      }]
                    }})
                  }}>Add extra step</Button></DataRowCell>: null}
                </div>
          </DataContainer>
        </>}
        cancelText='Cancel'
        submitText='Save changes'
        onCancel={()=>setEditingFlow(undefined)}
        onConfirm={async ()=>{
          let res = await fetch(
            `${Config.base_url}/api/v1/admin/accounts/${account.id}/profiles/${editingFlow.account_profile_id}`,
            {
              method: 'PUT',
              headers: {
                Authorization: "Bearer " + authenticated.token,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                profile_id: editingFlow.profile_id,
                extra_steps: editingFlow.extra_steps,
              })
            }
          )
          if(res.ok) {
            await loadAccountProfiles()
            setEditingFlow(undefined)
          }
        }}
      />}
      {account && addEditCredential && <ConfirmModal
        width="600px"
        isOpen={!!addEditCredential}
        header={account.credentials.Data?.find(a=>a.id === addEditCredential.id) ? `You're editing the credential '${addEditCredential.id}'` : `You're adding a new credential`}
        text={<>
          <DataContainer>
            <DataRow>
              <DataRowCell><DataLabel>Crendtial type</DataLabel></DataRowCell>
              <DataRowCell>
                <Select value={addEditCredential.type} onChange={(evt)=>setAddEditCredential({...addEditCredential, type: evt.target.value as "AWS" | "FTP"})}>
                  {["AWS", "FTP"].map(t=>(<option key={t} value={t}>{t}</option>))}
                </Select>
              </DataRowCell>
            </DataRow>
            {addEditCredential.type === "AWS" ? <>
                {dataRow("Credential ID", 
                  addEditCredential.id, 
                  (value: string)=>setAddEditCredential({...addEditCredential, id: value}))
                }
                {dataRow("Bucket", 
                  (addEditCredential.details as AWSCredentialDetails).bucket, 
                  (value: string)=>setAddEditCredential({...addEditCredential, details: {...addEditCredential.details, bucket: value}}))
                }
                {dataRow("Region", 
                  (addEditCredential.details as AWSCredentialDetails).region, 
                  (value: string)=>setAddEditCredential({...addEditCredential, details: {...addEditCredential.details, region: value}}))
                }
                {dataRow("Access Key", 
                  (addEditCredential.details as AWSCredentialDetails).access_key_id, 
                  (value: string)=>setAddEditCredential({...addEditCredential, details: {...addEditCredential.details, access_key_id: value}}))
                }
                {dataRow("Secret Access Key", 
                  (addEditCredential.details as AWSCredentialDetails).secret_access_key, 
                  (value: string)=>setAddEditCredential({...addEditCredential, details: {...addEditCredential.details, secret_access_key: value}}))
                }
            </> : "Not implemented yet"}
          </DataContainer>
        </>}
        cancelText='Cancel'
        submitText='Save changes'
        onCancel={()=>setAddEditCredential(undefined)}
        onConfirm={()=>{
          if(!addEditCredential.id) return
          if(account.credentials.Data?.find(a=>a.id === addEditCredential.id)){
            const newArr = account.credentials.Data.map(c=>{
              if(c.id === addEditCredential.id) {
                return addEditCredential
              }
              return c
            })
            setAccount({...account, credentials: {...account.credentials, Valid: true, Data: newArr}})
          } else {
            setAccount({...account, credentials: {...account.credentials, Valid: true, Data: account.credentials.Data ? [...account.credentials.Data, addEditCredential] : [addEditCredential]}})
          }
          setAddEditCredential(undefined)
        }}
      />}
    </Page>
  )
}

export default AccountPage