import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Config } from '../config/config';
import { AuthContext, InitialAuthContext } from '../context/auth';
import {AccountProfile, Profile, ProfileOutput} from '../types/types'
import { Button, DataContainer, DataLabel, DataRow, DataRowCell, DataValue, ExpandableSection, Page, PageHeader, TextInput, dataRow } from '../components/common';
import 'react-json-pretty/themes/monikai.css';
import { v4 as uuidv4 } from 'uuid'
import { ConfirmModal } from '../components/modal';


const NEW_PROFILE_TEMPATE: Profile = {
  id: '',
  outputs: [
    {
        Priority: 5,
        UploadVideoOnceComplete: true,
        GenerateSmilOnceComplete: true,
        UpdateMediaOnceComplete: true,
        CannotBeSkipped: false,
        CanBeBundled: false,
        Snapshot: {
            Format: "jpeg",
            Times: [
                5,
                10
            ]
        },
        Size: 576,
        VideoHeight: null,
        VideoWidth: null,
        VideoBitrate: 1000000,
        VideoMaxBitrate: null,
        VideoMinBitrate: null,
        BufSize: null,
        VideoCodec: null,
        AudioBitrate: 128000,
        AudioCodec: "AAC",
        Framerate: 30,
        Profile: "",
    },
    {
        Priority: 5,
        UploadVideoOnceComplete: true,
        GenerateSmilOnceComplete: true,
        UpdateMediaOnceComplete: true,
        CannotBeSkipped: false,
        CanBeBundled: false,
        Snapshot: {
            Format: "jpeg",
            Times: [
                5,
                10
            ]
        },
        Size: 720,
        VideoHeight: null,
        VideoWidth: null,
        VideoBitrate: 2500000,
        VideoMaxBitrate: null,
        VideoMinBitrate: null,
        BufSize: null,
        VideoCodec: null,
        AudioBitrate: 128000,
        AudioCodec: "AAC",
        Framerate: 60,
        Profile: "",
    },
    {
        Priority: 5,
        UploadVideoOnceComplete: true,
        GenerateSmilOnceComplete: true,
        UpdateMediaOnceComplete: true,
        CannotBeSkipped: false,
        CanBeBundled: false,
        Snapshot: {
            Format: "jpeg",
            Times: [
                5,
                10
            ]
        },
        Size: 1080,
        VideoHeight: null,
        VideoWidth: null,
        VideoBitrate: 4000000,
        VideoMaxBitrate: null,
        VideoMinBitrate: null,
        BufSize: null,
        VideoCodec: null,
        AudioBitrate: 128000,
        AudioCodec: "AAC",
        Framerate: 60,
        Profile: "",
    },
    {
        Priority: 5,
        UploadVideoOnceComplete: true,
        GenerateSmilOnceComplete: true,
        UpdateMediaOnceComplete: true,
        CannotBeSkipped: true,
        CanBeBundled: false,
        Snapshot: {
            Format: "jpeg",
            Times: [
                5,
                10
            ]
        },
        Size: 432,
        VideoHeight: null,
        VideoWidth: null,
        VideoBitrate: 700000,
        VideoMaxBitrate: null,
        VideoMinBitrate: null,
        BufSize: null,
        VideoCodec: null,
        AudioBitrate: 128000,
        AudioCodec: "AAC",
        Framerate: 30,
        Profile: "",
    }
  ]
}


function ProfilePage() {
  const {profileId} = useParams()
  const {authenticated, setAuthenticated} = useContext(AuthContext)
  const navigate = useNavigate()

  const [profile, setProfile] = useState<Profile | undefined>(profileId === 'new' ? NEW_PROFILE_TEMPATE : undefined)

  const [profileAccounts, setProfileAccounts] = useState<AccountProfile[]>([])

  const [updateKey, setUpdateKey] = useState(uuidv4())

  const [isChangeModalOpen, setIsChangeModalOpen] = useState(false)

  const loadProfile = useCallback(async () => {
    if(profileId === 'new')
      return
    let res = await fetch(
      `${Config.base_url}/api/v1/admin/profiles/${profileId}`,
      {
        headers: {
          Authorization: "Bearer " + authenticated.token,
        },
      }
    );
    if (res.status !== 200) {
      if (res.status === 401) {
        setAuthenticated(InitialAuthContext.authenticated);
      }
      return;
    }
    let resJsonProfiles = await res.json();
    if (resJsonProfiles.ok) {
      setProfile(resJsonProfiles.profiles as Profile)
      let res = await fetch(
        `${Config.base_url}/api/v1/admin/profiles/${profileId}/accounts`,
        {
          headers: {
            Authorization: "Bearer " + authenticated.token,
          },
        }
      );
      if (res.status !== 200) {
        if (res.status === 401) {
          setAuthenticated(InitialAuthContext.authenticated);
        }
        return;
      }
      let resJson = await res.json();
      if(resJson.ok)
        setProfileAccounts(resJson.profile_accounts as AccountProfile[])
    }
  }, [profileId, authenticated.token, setAuthenticated])

  useEffect(()=>{loadProfile()}, [loadProfile])

  const setProfileOutputValue = useCallback(function <K extends keyof ProfileOutput>(idx: number, field: K, value: ProfileOutput[K]) {
    if(!profile)
      return
    const newOutput = {...profile.outputs[idx]}
    newOutput[field] = value
    const newOutputs = [...profile.outputs]
    newOutputs[idx] = newOutput
    setProfile({...profile, outputs: newOutputs})
    setUpdateKey(uuidv4())
  }, [setProfile, profile])


  const saveProfile = useCallback(async () => {
    if(!profile || !profile.id || profile.id === 'new'){
      return
    }
    let res = await fetch(
      `${Config.base_url}/api/v1/admin/profiles${profileId === 'new' ? '' : `/${profileId}`}`,
      {
        method: profileId === 'new' ? 'POST' : 'PUT',
        headers: {
          Authorization: "Bearer " + authenticated.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(profile)
      }
    )
    if(res.ok) {
      navigate(`/profiles/${profile.id}`)
    }
  }, [authenticated.token, navigate, profile, profileId])

  console.log(profile)

  return (
    <Page>
      <PageHeader>Profile {profileId}</PageHeader>
      {profile ? <DataContainer>      
          {profileId === 'new' ? dataRow("Profile ID", profile.id, (value: string)=>setProfile({...profile, id: value})) : null}
          {profile.outputs.map((profileOutput: ProfileOutput, idx: number)=>{
          return <ExpandableSection key={profileOutput.VideoBitrate} header={
            <DataRow>
              <DataRowCell>
                <DataLabel>Output {idx+1}</DataLabel>
              </DataRowCell>
              <DataRowCell>
                <DataValue>
                  {profileOutput.VideoBitrate + profileOutput.AudioBitrate + ' bit/s'}
                </DataValue> 
              </DataRowCell>
              <DataRowCell>
                <Button $danger={true} style={{marginLeft: 'auto', marginRight: '50px'}} onClick={()=>{
                  const newOutputs = [...profile.outputs].filter((p, i)=>i!==idx)
                  setProfile({...profile, outputs: newOutputs})
                }}>Remove</Button>
              </DataRowCell>
            </DataRow>
            // dataRow(`Output ${idx+1}`, profileOutput.VideoBitrate + profileOutput.AudioBitrate + ' bit/s')
          }>
            <div style={{display: 'flex'}}><div style={{width: '5px', height: '100%', backgroundColor:'#ddf'}}></div>
              <DataContainer key={updateKey}>
                <DataRow key={"Snapshots"}>
                  <DataRowCell>
                    <DataLabel>Snapshots</DataLabel>
                  </DataRowCell>
                  <DataRowCell>
                    <DataValue>
                      {/* <TextInput defaultValue={profileOutput.Snapshot.Format} onBlur={(evt) => setProfileOutputValue(idx, "Snapshot", {...profileOutput.Snapshot, Format: evt.target.value})}/> */}
                      <div style={{marginLeft: '10px', display:'inline'}}>{'jpeg @ '}</div>
                      <TextInput defaultValue={profileOutput.Snapshot?.Times?.join(', ') || ""} onBlur={(evt) => setProfileOutputValue(idx, "Snapshot", {...profileOutput.Snapshot, Times: evt.target.value.split(',').map(s=>Number.parseInt(s.trim(), 10)).filter(v=>!isNaN(v))})}/>
                      seconds
                    </DataValue> 
                  </DataRowCell>
                </DataRow>
                {dataRow("Size", profileOutput.Size, (value: number)=>setProfileOutputValue(idx, "Size", value))}
                {dataRow("Video bitrate", profileOutput.VideoBitrate, (value: number)=>setProfileOutputValue(idx, "VideoBitrate", value))}
                {dataRow("Video max bitrate", profileOutput.VideoMaxBitrate || 0, (value: number)=>setProfileOutputValue(idx, "VideoMaxBitrate", value))}
                {dataRow("Video min bitrate", profileOutput.VideoMinBitrate || 0, (value: number)=>setProfileOutputValue(idx, "VideoMinBitrate", value))}
                {dataRow("Buffer size", profileOutput.BufSize || 0, (value: number)=>setProfileOutputValue(idx, "BufSize", value))}
                {/* {dataRow("Video codec", profileOutput.VideoCodec, (value: string)=>setProfileOutputValue(idx, "VideoCodec", value))} */}
                {dataRow("Framerate", profileOutput.Framerate, (value: number)=>setProfileOutputValue(idx, "Framerate", value))}
                {dataRow("Audio bitrate", profileOutput.AudioBitrate, (value: number)=>setProfileOutputValue(idx, "AudioBitrate", value))}
                {/* {dataRow("Audio codec", profileOutput.AudioCodec, (value: string)=>setProfileOutputValue(idx, "AudioCodec", value))} */}
                {dataRow("Can be bundled", profileOutput.CanBeBundled, (value: boolean)=>setProfileOutputValue(idx, "CanBeBundled", value))}
                {dataRow("Can be skipped", !profileOutput.CannotBeSkipped, (value: boolean)=>setProfileOutputValue(idx, "CannotBeSkipped", !value))}
                {dataRow("Generate SMIL directly", profileOutput.GenerateSmilOnceComplete, (value: boolean)=>setProfileOutputValue(idx, "GenerateSmilOnceComplete", value))}
                {dataRow("Update media when done", profileOutput.UpdateMediaOnceComplete, (value: boolean)=>setProfileOutputValue(idx, "UpdateMediaOnceComplete", value))}
                {dataRow("Upload media when done", profileOutput.UploadVideoOnceComplete, (value: boolean)=>setProfileOutputValue(idx, "UploadVideoOnceComplete", value))}
                {dataRow("Priority (1-10)", profileOutput.Priority, (value: number)=>setProfileOutputValue(idx, "Priority", value))}
                {/* {dataRow("Profile", profileOutput.Profile, (value: string)=>setProfileOutputValue(idx, "Profile", value))} */}
              </DataContainer>
            </div>
          </ExpandableSection>
        })}
        <Button style={{marginTop: '20px', marginLeft: 'auto'}} onClick={()=>{
            let newProfile = {...profile}
            newProfile.outputs = [...newProfile.outputs, {
              Priority: 5,
              UploadVideoOnceComplete: true,
              GenerateSmilOnceComplete: true,
              UpdateMediaOnceComplete: true,
              CannotBeSkipped: false,
              CanBeBundled: false,
              Snapshot: {
                  Format: "jpeg",
                  Times: [
                      5,
                      10
                  ]
              },
              Size: 1080,
              VideoHeight: null,
              VideoWidth: null,
              VideoBitrate: 4000000,
              VideoCodec: null,
              AudioBitrate: 128000,
              AudioCodec: "AAC",
              Framerate: 60,
              Profile: "",
          } as ProfileOutput]
            setProfile(newProfile)
          }} $primary={true}>Add Output</Button>
        <Button style={{marginTop: '20px', marginLeft: 'auto'}} onClick={()=>{
          if(profileId === 'new'){
            saveProfile()
          } else {
            setIsChangeModalOpen(true)
          }
          }} $primary={true}>{profileId === 'new' ? 'Create' : 'Save'}</Button>
      </DataContainer>: null}
      {profileAccounts.length > 0 ?
        <DataContainer><ExpandableSection defaultExpanded={true} header={<DataRow><DataRowCell><DataLabel>Accounts using this profile</DataLabel></DataRowCell></DataRow>}>
          <DataContainer>
            {profileAccounts.map(profileAccount => <DataRow>
                <DataRowCell><DataLabel>
                  {profileAccount.account_id}
                </DataLabel></DataRowCell>
                <DataValue>
                  {profileAccount.account_profile_id}
                </DataValue>
              </DataRow>)}
          </DataContainer>
        </ExpandableSection></DataContainer>
      : <DataContainer><i>No Accounts use this Profile</i></DataContainer>}
      <ConfirmModal
        isOpen={isChangeModalOpen}
        header="You're about to update this profile"
        text={<>
          <p>Are you sure you want to update this profile?</p>
          <p>It is being used by <b style={{textDecoration: 'underline'}}>{profileAccounts.length}</b> accounts.</p>
        </>}
        cancelText='Cancel'
        submitText='Go ahead'
        onCancel={()=>setIsChangeModalOpen(false)}
        onConfirm={()=>{
          saveProfile()
          setIsChangeModalOpen(false)
        }}
      />
    </Page>
  )
}

export default ProfilePage