import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../context/auth'
import { Button } from '../components/common'
import pegacorn from './pegacorn.png'
import { Config } from '../config/config'


const Login = () => {
  const {setAuthenticated} = useContext(AuthContext)

  const [user, setUser] = useState("")
  const [pwd, setPwd] = useState("")

  const navigate = useNavigate();

  const handleLogin = async () => {
    let res = await fetch(
      `${Config.base_url}/api/v1/login`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({username: user, password: pwd})
      }
    )
    if(res.ok) {
      const body = await res.json() as {ok: boolean; token: string; is_superadmin: boolean; accounts: string[]}
      const acc = body.accounts.length > 0 ? body.accounts[0] : ""
      setAuthenticated({
        account: acc,
        token: body.token,
        isSuperadmin: body.is_superadmin,
        availableAccounts: body.accounts,
      })
    }
    navigate('/')
  }

  const handleEnterKey = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    console.log(evt.key)
    if(evt.key === 'Enter') {
      evt.preventDefault()
      handleLogin()
    }
  }

  return (
    <div style={{paddingTop: '0px', alignContent: 'center', display: 'flex', width:'300px', textAlign: 'center', flexDirection:'column', margin:'auto'}}>
      <img src={pegacorn} alt="pegacorn"/>
      <h2 style={{textAlign: 'center', color: '#338'}}>Qbrick<br/>Pegacoder</h2>
      <p style={{textAlign: 'center'}}>login</p>
      <input placeholder="Username" style={{margin: '5px'}} type="text" onKeyDown={handleEnterKey} onChange={(evt)=>setUser(evt.target.value)} />
      <input placeholder="Password" style={{margin: '5px'}} type="password" onKeyDown={handleEnterKey} onChange={(evt)=>setPwd(evt.target.value)} />
      {/* <button style={{margin: '5px'}} onClick={() => handleLogin()}>Login</button> */}
      <Button type='submit' $primary={true} onClick={() => handleLogin()}>Login</Button>
    </div>
  )
}

export default Login