import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Page, PageHeader } from '../components/common'
import { Config } from '../config/config';
import { AuthContext, InitialAuthContext } from '../context/auth';
import { Account } from '../types/types';
import { AccountList } from '../components/accountList';
import { useNavigate } from 'react-router-dom';

function Accounts() {
  
  const {authenticated, setAuthenticated} = useContext(AuthContext)
  const navigate = useNavigate()

  const [accounts, setAccounts] = useState([] as Account[])

  const loadAccounts = useCallback(async () => {
    let res = await fetch(
      `${Config.base_url}/api/v1/admin/accounts`,
      {
        headers: {
          Authorization: "Bearer " + authenticated.token,
        },
      }
    );
    if (res.status !== 200) {
      if (res.status === 401) {
        setAuthenticated(InitialAuthContext.authenticated);
      }
      return;
    }
    let resJson = await res.json();
    if (resJson.ok) setAccounts((resJson.accounts as Account[]).sort((a,b)=>a.id<b.id? -1: 1));
  }, [authenticated, setAuthenticated])

  useEffect(()=>{
    loadAccounts()
  }, [loadAccounts])
  return (
    <Page>
      <PageHeader>Accounts<Button onClick={()=>navigate('/accounts/new')} $primary={true} style={{position: 'absolute', right:'20px', top: '9px'}}>Add New Account</Button></PageHeader>
      <AccountList accounts={accounts}/>
    </Page>
  )
}

export default Accounts