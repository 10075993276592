import { createContext, ReactNode, useEffect, useState } from 'react'

type Props = {
  children?: ReactNode;
}

type IAuthContext = {
  authenticated: {
    account: string,
    token: string,
    isSuperadmin: boolean,
    availableAccounts: string[],
  };
  setAuthenticated: (newState: {
    account: string,
    token: string,
    isSuperadmin: boolean,
    availableAccounts: string[],
  }) => void
}

export const InitialAuthContext = {
  authenticated: {
    account: "",
    token: "",
    isSuperadmin: false,
    availableAccounts: [],
  },
  setAuthenticated: (newState: {
    account: string,
    token: string,
    isSuperadmin: boolean,
    availableAccounts: string[],
  }) => {}
}

const AuthContext = createContext<IAuthContext>(InitialAuthContext)

const AuthProvider = ({children}: Props) => {
  //Initializing an auth state with false value (unauthenticated)
  const account = localStorage.getItem("account")
  const availableAccounts = localStorage.getItem("availableAccounts")?.split(',').filter(v=>!!v)
  const token = localStorage.getItem("token")
  const isSuperadmin = localStorage.getItem("isSuperadmin") === "true"
  const [ authenticated, setAuthenticated ] = useState({account: account || "", token: token || "", availableAccounts: availableAccounts || [], isSuperadmin: isSuperadmin})

  useEffect(()=>{
    localStorage.setItem("account", authenticated.account)
    localStorage.setItem("availableAccounts", authenticated.availableAccounts.join(','))
    localStorage.setItem("token", authenticated.token)
    localStorage.setItem("isSuperadmin", ""+authenticated.isSuperadmin)
  }, [authenticated.account, authenticated.token, authenticated.availableAccounts, authenticated.isSuperadmin])

  return (
    <AuthContext.Provider value={{authenticated, setAuthenticated}}>
      {children}
    </AuthContext.Provider>
  )
}

export {  AuthContext, AuthProvider }