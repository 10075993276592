import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AuthContext, InitialAuthContext } from '../context/auth';
import { Config } from '../config/config';
import { Account, Job, JobWithAccountName } from '../types/types';
import { Page, PageHeader, TextInput } from '../components/common';
import { JobList } from '../components/jobListItem';
import { UpdateContext } from '../context/update';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { TransposedJobBucket, transposeJobs } from '../util/utils';
import ReactSwitch from 'react-switch';
import { useLocation } from 'react-router-dom';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Home() {

  const query = useQuery()
  const queryAccount = query.get('accountId')
  
  const {authenticated, setAuthenticated} = useContext(AuthContext)
  const {update} = useContext(UpdateContext)
  const [doUpdate, setDoUpdate] = useState(1)
  const [jobs, setJobs] = useState([] as JobWithAccountName[])
  const [accounts, setAccounts] = useState([] as Account[])
  const [chartData, setChartData] = useState([] as TransposedJobBucket[])
  const [showSuccess, setShowSuccess] = useState(true)
  const [showError, setShowError] = useState(true)
  const [showInProgress, setShowInProgress] = useState(true)

  const [filterAccountID, setFilterAccountID] = useState(queryAccount || '')
  const [filterMediaID, setFilterMediaID] = useState("")

  const loadData = useCallback(async () => {
    let filterStatus = 
      showInProgress && showError && showSuccess ? '?status=all' :
      (showInProgress && showError ? '' : 
      (showInProgress && showSuccess) ? '?status=IN_PROGRESS,NOT_STARTED,SUCCESS,IN_QUEUE,WAITING_TO_RETRY' : 
      (showError && showSuccess ? '?status=ERROR,SUCCESS' : 
      (showInProgress ? '?status=IN_PROGRESS,NOT_STARTED,IN_QUEUE,WAITING_TO_RETRY' :
      (showError ? '?status=ERROR' :
      (showSuccess ? '?status=SUCCESS': false)))))
    if(filterStatus === false){
      setJobs([])
      setChartData([])
      return
    }
    if(filterMediaID) {
      if(filterStatus)
        filterStatus += `&mediaId=${filterMediaID}`
      else 
        filterStatus = `?mediaId=${filterMediaID}`
    }
    let res = await fetch(
      `${Config.base_url}/api/v1/accounts/${authenticated.account || filterAccountID || "all"}/jobs${filterStatus}`,
      {
        headers: {
          Authorization: "Bearer " + authenticated.token,
        },
      }
    );
    if (res.status !== 200) {
      if (res.status === 401) {
        setAuthenticated(InitialAuthContext.authenticated);
      }
      return;
    }
    let resJson = await res.json();
    if (resJson.ok) {
      const jobsWithAccountName = (resJson.jobs as Job[]).map(j=>({...j, account_name: accounts.find(a=>a.id === j.account_id)?.name.String} as JobWithAccountName))
      setJobs(jobsWithAccountName)
      const buckets = transposeJobs(resJson.jobs as Job[], 100)
      // console.log(buckets)
      setChartData(buckets)
    }
  }, [accounts, authenticated.account, authenticated.token, setAuthenticated, showInProgress, showError, showSuccess, filterAccountID, filterMediaID])

  const loadAccounts = useCallback(async () => {
    if(!authenticated.isSuperadmin) return

    let res = await fetch(
      `${Config.base_url}/api/v1/admin/accounts`,
      {
        headers: {
          Authorization: "Bearer " + authenticated.token,
        },
      }
    );
    if (res.status !== 200) {
      if (res.status === 401) {
        setAuthenticated(InitialAuthContext.authenticated);
      }
      return;
    }
    let resJson = await res.json();
    if (resJson.ok) {
      setAccounts(resJson.accounts as Account[])
    }
  }, [authenticated.token, setAuthenticated, authenticated.isSuperadmin])

  useEffect(()=>{
    setTimeout(()=>setDoUpdate(doUpdate+1), 5000)
  }, [doUpdate])

  useEffect(()=>{
      if(update) {
        loadData()
      }
    }, [update, doUpdate, loadData])

  useEffect(() => {
    loadData()
  }, [loadData]);

  useEffect(() => {
    loadAccounts()
  }, [loadAccounts]);

  useEffect(()=>{
    console.log('Setting query!', queryAccount)
    setFilterAccountID(queryAccount || '')
  }, [queryAccount, setFilterAccountID])

  const FilterSwitch = (props: {checked: boolean, onChange: (value: boolean)=> void}) => (
      <ReactSwitch 
        onColor="#86d3ff"
        handleDiameter={15}
        uncheckedIcon={false}
        checkedIcon={false}
        onHandleColor="#2693e6"
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={15}
        width={30}
        checked={props.checked}
        onChange={()=>props.onChange(!props.checked)} 
      />
  )
  
  return (
    <Page>
      <PageHeader>Home</PageHeader>
      <div style={{display:'flex', justifyContent:'center', margin: '0 40px 10px 40px', alignItems: 'center'}}>
        <label>In Progress</label><FilterSwitch checked={showInProgress} onChange={setShowInProgress} />
        <label>Success</label><FilterSwitch checked={showSuccess} onChange={setShowSuccess} />
        <label>Error</label><FilterSwitch checked={showError} onChange={setShowError} />
        <div style={{height: '20px', marginLeft: 'auto', display: 'inline'}}>
          {!authenticated.account ? <TextInput defaultValue={filterAccountID} onBlur={(evt)=>setFilterAccountID(evt.target.value.trim())} placeholder='Account ID' style={{height: '20px'}}  /> : null}
          <TextInput defaultValue={filterMediaID} onBlur={(evt)=>setFilterMediaID(evt.target.value.trim())} placeholder='Media ID' style={{height: '20px'}} />
        </div>
      </div>
      <div style={{paddingRight: '40px'}}>
        <ResponsiveContainer width='100%' aspect={4.0/0.5}>
          <AreaChart width={200} height={200} data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="endTime" />
            <YAxis />
            <Tooltip />
            <Area stackId={1} type="monotone" dataKey="SUCCESS" stroke="#5f5" fill="#bfb" />
            <Area stackId={1} type="monotone" dataKey="ERROR" stroke="#f55" fill="#fbb" />
            <Area stackId={1} type="monotone" dataKey="IN_PROGRESS" stroke="#55f" fill="#bbf" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <JobList jobs={jobs}/>
    </Page>
  )
}

export default Home