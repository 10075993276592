import { createContext, ReactNode, useEffect, useState } from 'react'

type Props = {
  children?: ReactNode;
}

type IUpdateContext = {
  update: boolean
  setUpdate: (newState: boolean) => void
}

const initialValue = {
  update: true,
  setUpdate: () => {}
}

const UpdateContext = createContext<IUpdateContext>(initialValue)

const UpdateProvider = ({children}: Props) => {
  //Initializing an auth state with false value (unauthenticated)
  const storedUpdate = localStorage.getItem("update") === 'true'
  const [ update, setUpdate ] = useState(storedUpdate)

  useEffect(()=>{
    localStorage.setItem("update", update ? 'true' : 'false')
  }, [update])

  return (
    <UpdateContext.Provider value={{update, setUpdate}}>
      {children}
    </UpdateContext.Provider>
  )
}

export {  UpdateContext, UpdateProvider }