import React from 'react'
import { Profile } from '../types/types';
import { useNavigate } from 'react-router-dom';
import { TR, TD, TH, Button } from './common';

export const ProfileListItem = (props: {profile: Profile, onDuplicate?: () => void}) => {
  const navigate = useNavigate()
  const profile = props.profile
  return (
      <TR onClick={()=>navigate(`/profiles/${profile.id}`)}>
        <TD>{profile.id}</TD>
        <TD>{profile.outputs?.length || 0}</TD>
        {!!props.onDuplicate ? <TD><Button $primary={true} onClick={ () => props.onDuplicate!()}>Duplicate</Button></TD> : null}
      </TR>
      )
}

export const ProfileList = (props: {profiles: Profile[], onDuplicate?: (id: string) => void}) => {
  return <table style={{borderSpacing: 0}}>
    <thead>
      <tr>
        <TH>Profile ID</TH>
        <TH>Number of outputs</TH>
      </tr>
    </thead>
    <tbody style={{maxHeight: 'calc(1000px - 300px)'}}>
      {props.profiles.map(profile=>(<ProfileListItem key={profile.id} profile={profile} onDuplicate={props.onDuplicate ? ()=>props.onDuplicate!(profile.id) : undefined} />))}
    </tbody>
  </table>
}