
import Modal from 'react-modal';
import { Button } from './common';

export const ConfirmModal = (props: { isOpen: boolean, onCancel: () => void, onConfirm: () => void, width?: string, cancelText: string, submitText: string, header: string, text: React.ReactNode}) =>  {
  return <Modal  style={{
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'center',
      inset: '50% auto auto 50%',
    }
  }} isOpen={props.isOpen}>
<div style={{width: props.width || '400px', margin: 'auto'}}>
  <h3>{props.header}</h3>
  {props.text}
  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
    <Button $primary={true} onClick={props.onCancel}>{props.cancelText}</Button>
    <Button $danger={true} onClick={props.onConfirm}>{props.submitText}</Button>
  </div>
</div>
</Modal>
}