import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Page, PageHeader } from '../components/common'
import { Config } from '../config/config';
import { AuthContext, InitialAuthContext } from '../context/auth';
import { Profile } from '../types/types';
import { ProfileList } from '../components/profileList';
import { useNavigate } from 'react-router-dom';

function Profiles() {
  
  const {authenticated, setAuthenticated} = useContext(AuthContext)
  const navigate = useNavigate()

  const [profiles, setProfiles] = useState([] as Profile[])

  const loadProfiles = useCallback(async () => {
    let res = await fetch(
      `${Config.base_url}/api/v1/admin/profiles`,
      {
        headers: {
          Authorization: "Bearer " + authenticated.token,
        },
      }
    );
    if (res.status !== 200) {
      if (res.status === 401) {
        setAuthenticated(InitialAuthContext.authenticated);
      }
      return;
    }
    let resJson = await res.json();
    if (resJson.ok) setProfiles(resJson.profiles as Profile[]);
  }, [authenticated, setAuthenticated])

  const duplicateProfile = useCallback(async (id: string) => {
    const profile = profiles.find((p)=>p.id === id)
    if(!profile) return
    
    let profId = profile.id + "-dup"

    // eslint-disable-next-line no-loop-func
    while(!!profiles.find((p)=>p.id === profId)){
      profId += "-dup"
    }

    profile.id = profId
    

    let res = await fetch(
      `${Config.base_url}/api/v1/admin/profiles`,
      {
        method: 'POST',
        headers: {
          Authorization: "Bearer " + authenticated.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(profile)
      }
    )
    if(res.ok) {
      navigate(`/profiles/${profile.id}`)
    }
  }, [authenticated.token, navigate, profiles])

  useEffect(()=>{
    loadProfiles()
  }, [loadProfiles])
  return (
    <Page>
      <PageHeader>Profiles<Button onClick={()=>navigate('/profiles/new')} $primary={true} style={{position: 'absolute', right:'20px', top: '9px'}}>Add New Profile</Button></PageHeader>
      <ProfileList profiles={profiles} onDuplicate={duplicateProfile}/>
    </Page>
  )
}

export default Profiles